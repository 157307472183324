import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { DealType, generatePipeline, StagesDataType } from "../../cfpipelines1/src/utils/generatePipeline";
import { toast } from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  singleStage: StagesDataType
  sortField: string
  sortOrder: string
  deals: DealType[]
  currentPageIndex: number
  requiredPageSize: number
  isMoveStage: boolean
  isDeleteStage: boolean
  pipelinesId: string[]
  dest_pipeline_id: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfstage1DetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      singleStage: { id: '', pipeline_id: "", deals: [], description: '', created_at: '', permission_level: '', rule_builder_id: null, stage_name: '', task: null, updated_at: '' },
      sortField: '',
      sortOrder: 'asc',
      deals: [],
      currentPageIndex: 0,
      requiredPageSize: 10,
      isMoveStage: false,
      isDeleteStage: false,
      dest_pipeline_id: '',
      pipelinesId: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const { stageId, pipelineId } = Object.fromEntries(new URLSearchParams(window.location.search));
    const storedStage = JSON.parse(localStorage.getItem("stage") || "{}");
    const pipelinesIdData = generatePipeline().map(item => item.id)
    const singlePipelineData = generatePipeline().find(pipeline => pipeline.id == pipelineId)
    const singleStage = singlePipelineData?.stages.find(stage => stage.id == stageId)
    if (singleStage) {
      this.setState({ singleStage, deals: singleStage.deals, })
    } else {
      this.setState({ singleStage: storedStage, deals: storedStage.deals, })
    }
    this.setState({ pipelinesId: pipelinesIdData })
  }

  handleSortDeals = (field: string) => {
    const { sortField, sortOrder } = this.state;

    if (sortField === field) {
      this.setState({
        sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortField: field,
        sortOrder: 'asc',
      });
    }
  };

  onPageChangeDeal = (page: number) => this.setState({ currentPageIndex: page })
  handlePreviousDeal = () => {
    if (this.state.currentPageIndex > 0) this.onPageChangeDeal(this.state.currentPageIndex - 1);
  };
  handlePageChangeDeal = (event: any, value: number) => {
    this.setState({ currentPageIndex: value - 1 });
  };

  handleNextDeal = (totalPages: number) => {
    if (this.state.currentPageIndex < totalPages - 1) this.onPageChangeDeal(this.state.currentPageIndex + 1);
  };
  getNumericDealId = (formattedId: any): number => {
    const match = formattedId.match(/\d+/);
    return match ? Number(match[0]) : NaN;
  };

  handleSortDealChange = () => {
    const { sortOrder, deals, sortField } = this.state;
    if (!Array.isArray(deals) || deals.length === 0) {
      return [];
    }

    const sortedData = [...deals].sort((a, b) => {

      let comparison = 0;

      if (sortField === 'id') {
        comparison = this.getNumericDealId(a.deal_id) - this.getNumericDealId(b.deal_id);
      } else if (sortField === 'name') {
        comparison = a.deal_name.localeCompare(b.deal_name);
      } else if (sortField === 'due_date') {
        const dateA = new Date(a.due_date).getTime();
        const dateB = new Date(b.due_date).getTime();
        comparison = dateA - dateB
      } else if (sortField === 'priority') {
        comparison = a.priority.localeCompare(b.priority);
      }

      return sortOrder === 'asc' ? comparison : -comparison;
    });

    return sortedData
  };

  toggleMoveStage = () => {
    this.setState(prevState => ({ isMoveStage: !prevState.isMoveStage }))
  }

  handleMoveStage = (event: any) => {
    event.preventDefault()
    const { singleStage, dest_pipeline_id } = this.state
    const updatedStage = { ...singleStage }
    updatedStage.pipeline_id = dest_pipeline_id
    this.setState({ singleStage: updatedStage })
    localStorage.setItem("stage", JSON.stringify(updatedStage))
    toast.success(`Stage moved successfully to pipeline ${this.state.dest_pipeline_id}`, { style: { fontSize: "1.6rem" } })
    this.toggleMoveStage()
  }

  handleChangeMoveStage = (event: any, newValue: any) => {
    this.setState({ dest_pipeline_id: newValue });
  };

  handleEditStage = () => {
    this.props.navigation.navigate('EditStageDetails', { stageId: this.state.singleStage.id })
  }
  toggleDeleteStage = () => {
    this.setState((prevState) => ({ isDeleteStage: !prevState.isDeleteStage }))
  }
  handleDeleteStage = () => {
    toast.success("Stage Deleted Successfully", { style: { fontSize: '1.6rem' } })
    localStorage.removeItem("stage")
    this.props.navigation.navigate("Cfstages1", { pipelineId: this.state.singleStage.pipeline_id })
  }
  // Customizable Area End
}
