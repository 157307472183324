import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from 'react-toastify';
import { generatePipeline, generateRandomDate } from "../../cfpipelines1/src/utils/generatePipeline";

type StageErrorType = {
  stage_name?: string
  description?: string
  permission_level?: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  stage_name: string,
  description: string,
  permission_level: string,
  isModalOpen: boolean,
  isEditModalOpen: boolean,
  editStageValue: string,
  pipeline_id: string,
  stageId: string,
  errors: StageErrorType
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfstages1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      stage_name: '',
      description: '',
      permission_level: '',
      isModalOpen: false,
      isEditModalOpen: false,
      editStageValue: '',
      pipeline_id: '',
      stageId: '',
      errors: {},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  validateStageFields = (stage_name: string, stage_description: string) => {
    const errors: StageErrorType = {};

    if (stage_name.length > 100) {
      errors.stage_name = "Stage name cannot exceed 100 characters.";
    }

    if (stage_description.length > 300) {
      errors.description = "Stage description cannot exceed 300 characters.";
    }

    return errors;
  };

  handleAddStage = (event: any) => {
    event.preventDefault()
    const { stage_name, description, pipeline_id } = this.state
    const addStageErrorMessages = this.validateStageFields(stage_name, description);

    if (Object.keys(addStageErrorMessages).length > 0) {
      this.setState({ errors: addStageErrorMessages });
      return;
    }
    const filterPipeline = generatePipeline().find(item => item.id == pipeline_id)
    if (!filterPipeline) {
      console.error('Pipeline not found');
      return;
    }
    const lastStage = filterPipeline.stages[filterPipeline.stages.length - 1];
    const lastStageNumber = parseInt(lastStage.id.split('S')[1]);
    const newStageNumber = lastStageNumber + 1;
    const newStageId = `#S${String(newStageNumber).padStart(6, '0')}`;
    const newStage = {
      id: newStageId,
      stage_name,
      description,
      task: null,
      permission_level: "Level 1",
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      pipeline_id: pipeline_id,
      rule_builder_id: null,
      deals: [
        {
          "deal_name": "Deal 1",
          "deal_id": "D00000001",
          "deal_desc": "This is the description for Deal 1",
          priority: "High",
          due_date:generateRandomDate()
        },
        {
          "deal_name": "Deal 2",
          "deal_id": "D00000002",
          "deal_desc": "This is the description for Deal 2",
          priority: "Medium",
          due_date: generateRandomDate()
        },
        {
          "deal_name": "Deal 3",
          "deal_id": "D00000003",
          "deal_desc": "This is the description for Deal 3",
          priority: 'Low',
          due_date: generateRandomDate()
        },

      ]
    };
    localStorage.setItem("stage", JSON.stringify(newStage))
    toast.success('Stage created successfully', { style: { fontSize: "1.6rem" } })
    this.props.navigation.navigate('Cfstage1details', { stageId: newStageId, pipelineId: pipeline_id })
  };

  async componentDidMount() {
    super.componentDidMount()
    this.getPipelineIdFromUrl()
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const updatedErrors = { ...prevState.errors };

      if (name === "stage_name" && value.length <= 100) {
        delete updatedErrors.stage_name;
      }

      if (name === "description" && value.length <= 300) {
        delete updatedErrors.description;
      }

      return {
        ...prevState,
        [name]: value,
        errors: updatedErrors,
      };
    });
  };

  getPipelineIdFromUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const pipeline_id = queryParams.get('pipelineId');
    if (pipeline_id)
      this.setState({ pipeline_id });
  }
  isFormComplete = () => {
    const { stage_name, description } = this.state;
    return (
      stage_name &&
      description
    );
  };
  // Customizable Area End
}
