import React from "react";
// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import { Box, Divider, Button, Typography, IconButton, Modal, TextField, MenuItem, FormControlLabel, Autocomplete, Radio } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { formatDateTimeField, formatDateTimeTable } from "../../cfpipelines1/src/utils/generatePipeline";
import PaginationComponent from "../../cfpipelines1/src/utils/PaginationComponent";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search'
// Customizable Area End

import Cfstage1DetailsController, {
    Props,
} from "./Cfstage1DetailsController";

export default class Cfstage1Details extends Cfstage1DetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderDealColumnTypography = (flex: number, text: string | number, field: string) => {
        const { sortField, sortOrder } = this.state;
        let sortIcon = null;
        if (sortField === field) {
            sortIcon = sortOrder === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />;
        } else {
            sortIcon = <ArrowDropDownIcon />;
        }

        return (
            <Typography variant="h6" onClick={() => this.handleSortDeals(field)} style={{ fontSize: '1.4rem', flex: flex, fontWeight: 700, color: '#3E3E3E' }}>{text}{text == "Actions" ? "" : sortIcon}</Typography>
        )
    }

    renderRowTypography = (flex: number, text: string | number) => {
        return (
            <Typography variant="h6" data-test-id="row-typography" style={{
                fontSize: '1.4rem',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                flex: flex, fontWeight: 600, cursor: 'pointer', color: '#203447'
            }}>{text}</Typography>
        )
    }

    renderModalButton = (contentDirection: string, toggleModal: ()=>void, text:string) =>{
        return <Box display="flex" justifyContent={contentDirection} gap={2} mt={3}>
        <Button onClick={toggleModal} variant="contained" sx={{
            backgroundColor: '#1A4A421A',
            color: '#1A4A4299',
            padding: '1rem 0.8rem',
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            fontSize: '1.2rem',
            width: '10rem',
            '&:hover': {
                backgroundColor: '#F5FEFD',
            }
        }}>
            Cancel
        </Button>
        <Button type="submit" variant="contained" sx={{
            backgroundColor: '#1A4A42',
            color: 'white',
            padding: '1rem 0.8rem',
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            fontSize: '1.2rem',
            width: '10rem',
            '&:hover': {
                backgroundColor: '#1B5C54',
            }
        }}>
            {text}
        </Button>
    </Box>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { singleStage, currentPageIndex, requiredPageSize, isMoveStage, dest_pipeline_id, pipelinesId, isDeleteStage } = this.state
        const sortedRows = this.handleSortDealChange()
        const paginatedRows = sortedRows.slice(currentPageIndex * requiredPageSize, (currentPageIndex + 1) * requiredPageSize);
        const startIndex = currentPageIndex * requiredPageSize + 1;
        const endIndex = Math.min((currentPageIndex + 1) * requiredPageSize, sortedRows.length);
        const totalResults = sortedRows.length;

        return (
            <Box sx={stageDetailContainerStyle}>
                <TopNav navigation={this.props.navigation} id="pipelines" />
                <Divider sx={stageDetailsDividerStyle} />
                <Box display="flex" justifyContent='space-between' marginBottom={2}>
                    <Box display='flex' alignItems='center' gap={1}>
                        <ArrowBackIcon style={{ fontSize: '2rem' }} onClick={this.props.navigation.goBack} />
                        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>{singleStage.stage_name}</Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                        <Button data-test-id="edit-stage-btn" onClick={this.handleEditStage} variant="contained" sx={{
                            backgroundColor: '#1A4A42',
                            color: '#FFF',
                            fontWeight: 700,
                            borderRadius: '0.8rem',
                            textTransform: 'none',
                            fontSize: '1.2rem',
                            whiteSpace: 'nowrap',
                            boxShadow: 'none',
                            width: '8rem',
                            '&:hover': {
                                backgroundColor: '#1B5C54',
                            }
                        }}>
                            Edit
                        </Button>
                        <Button data-test-id="move-stage-btn" onClick={this.toggleMoveStage} variant="contained" sx={{
                            backgroundColor: '#FFF',
                            color: '#1A4A42',
                            fontWeight: 700,
                            borderRadius: '0.8rem',
                            textTransform: 'none',
                            fontSize: '1.2rem',
                            whiteSpace: 'nowrap',
                            border: '0.1rem solid #1A4A42',
                            width: '8rem',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: '#F5FEFD',
                            }
                        }}>
                            Move
                        </Button>
                        <Button data-test-id="delete-stage-btn" onClick={this.toggleDeleteStage} variant="contained" sx={{
                            backgroundColor: '#EF444433',
                            color: '#DC2626',
                            fontWeight: 700,
                            borderRadius: '0.8rem',
                            textTransform: 'none',
                            fontSize: '1.2rem',
                            whiteSpace: 'nowrap',
                            boxShadow: 'none',
                            width: '8rem',
                            '&:hover': {
                                backgroundColor: '#EF444455',
                            }
                        }}>
                            Delete
                        </Button>
                    </Box>
                </Box>
                <Box display='flex' flexDirection='column' flexWrap='wrap' gap={1} marginBottom={1}>
                    <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524" }}>Stage Details</Typography>
                    <Box display='flex' flexDirection='column' flexWrap='wrap' gap={0.5}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DESCRIPTION</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#292524" }}>{singleStage.description}</Typography>
                    </Box>

                </Box>
                <Box display="flex" flexWrap='wrap'>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>STAGE ID</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{singleStage.id}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DATE CREATED</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{formatDateTimeField(singleStage.created_at)}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>LAST EDITED</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{formatDateTimeField(singleStage.updated_at)}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>ASSOCIATED PIPELINE</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{singleStage.pipeline_id}</Typography>
                    </Box>
                </Box>
                <Box sx={{ margin: "1.5rem 0", display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524" }}>Deals</Typography>
                    <Button variant="contained" sx={{
                        backgroundColor: '#1A4A42',
                        color: '#FFF',
                        fontWeight: 700,
                        borderRadius: '0.8rem',
                        textTransform: 'none',
                        fontSize: '1.2rem',
                        whiteSpace: 'nowrap',
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: '#1B5C54',
                        }
                    }} >Create New Deal</Button>
                </Box>
                <Box display="flex" flexDirection='column' gap={1}>
                    <Box display="flex" style={{ backgroundColor: '#29252414', padding: '0.5rem 1rem', borderRadius: ' 0.8rem' }}>
                        {this.renderDealColumnTypography(3, 'Deal ID', 'id')}
                        {this.renderDealColumnTypography(5, 'Name', 'name')}
                        {this.renderDealColumnTypography(3, 'Priority', 'priority')}
                        {this.renderDealColumnTypography(3, 'Due Date', 'due_date')}
                        {this.renderDealColumnTypography(2, 'Actions', 'actions')}
                    </Box>
                    {paginatedRows.length > 0 ? paginatedRows.map(row => (
                        <Box display='flex' key={row.deal_id} style={{ padding: '0.5rem 1rem', borderBottom: '0.1rem solid #1A4A421A', alignItems: 'center' }}>
                            {this.renderRowTypography(3, `#${row.deal_id}`)}
                            {this.renderRowTypography(5, row.deal_name)}
                            {this.renderRowTypography(3, row.priority)}
                            {this.renderRowTypography(3, formatDateTimeTable(row.due_date))}
                            <Box style={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <IconButton data-test-id="duplicate-btn" sx={stageDetailAddButtonStyle} style={{ padding: "0.6rem" }} >
                                    <ZoomOutMapIcon />
                                </IconButton>
                                <IconButton data-test-id="edit-btn" sx={stageDetailAddButtonStyle} style={{ padding: "0.6rem" }} >
                                    <EditIcon />
                                </IconButton>
                                <IconButton data-test-id="delete-btn" sx={stageDetailDeleteIconStyle} style={{ padding: "0.6rem" }} >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )) : <Box display='flex' justifyContent='center' alignItems='center'><Typography variant="h5">No Deals Associated, Create a new one</Typography></Box>}
                </Box>
                <PaginationComponent currentPageIndex={currentPageIndex} requiredPageSize={requiredPageSize} startIndex={startIndex} endIndex={endIndex} totalResults={totalResults} handlePageChange={this.handlePageChangeDeal} />
                <Modal
                    open={isMoveStage}
                    onClose={this.toggleMoveStage}
                    aria-labelledby="add-stage-modal"
                    aria-describedby="add-stage-form"
                >
                    <Box sx={stageDetailsModalStyle}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <Typography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '2rem' }}>Move Stage {singleStage.id}</Typography>
                            <IconButton onClick={this.toggleMoveStage}>
                                <CloseIcon style={{ height: "2rem", width: '2rem' }} />
                            </IconButton>
                        </Box>

                        <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem", padding: 0, width: '100%' }} />

                        <Box component="form" noValidate autoComplete="off" onSubmit={this.handleMoveStage}>
                            <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#64748B", fontWeight: 700, fontSize: '1.4rem' }}>
                                Source Pipeline ID
                            </Typography>
                            <TextField
                                name="pipeline_id"
                                data-test-id="pipeline_id"
                                value={singleStage.pipeline_id}
                                fullWidth
                                disabled
                                aria-readonly
                                InputLabelProps={{ shrink: false }}
                                style={{ marginBottom: "0.8rem" }}
                                inputProps={{
                                    readOnly: true,
                                    sx: {
                                        padding: '0.8rem',
                                        fontWeight: 400,
                                        fontSize: '1.6rem',
                                    },
                                }}
                            />
                            <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#64748B", fontWeight: 700, fontSize: '1.4rem' }}>
                                Destination Pipeline ID
                            </Typography>
                            <Autocomplete
                                value={dest_pipeline_id}
                                onChange={this.handleChangeMoveStage}
                                options={pipelinesId}
                                getOptionLabel={(option) => option}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        paddingRight: '0 !important',
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="dest_pipeline_id"
                                        data-test-id="dest_pipeline_id"
                                        fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                padding: '0 !important',
                                            },
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: <SearchIcon sx={{ marginRight: '0.8rem', color: '#64748B' }} />,
                                        }}
                                        inputProps={{
                                            sx: {
                                                fontSize: '1.6rem',
                                                fontWeight: 400,
                                                display: 'flex',
                                                alignItems: 'center'
                                            },
                                            ...params.inputProps,
                                        }}
                                        InputLabelProps={{ shrink: false }}
                                    />
                                )}
                                renderOption={(props, option, state) => {
                                    return (
                                        <MenuItem {...props} key={option} value={option}
                                            sx={{
                                                fontSize: '1.6rem',
                                                fontWeight: 400,
                                                backgroundColor: dest_pipeline_id === option ? '#1A4A421A' : 'white',
                                                '&:hover': {
                                                    backgroundColor: dest_pipeline_id === option ? '#1A4A421A' : '#f0f0f0',
                                                },
                                            }}
                                        >
                                            <FormControlLabel
                                                value={option}
                                                control={
                                                    <Radio
                                                        checked={dest_pipeline_id === option}
                                                        onChange={this.handleChangeMoveStage}
                                                        value={option}
                                                        name="dest_pipeline_id"
                                                        sx={{
                                                            padding: '0 0.8rem',
                                                            '&.Mui-checked': {
                                                                color: '#1A4A42',
                                                            },
                                                            '&.MuiRadio-root': {
                                                                color: '#1A4A42',
                                                            },
                                                            '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                                                                backgroundColor: '#1A4A421A',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={<Typography sx={{ fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>
                                                    {option}
                                                </Typography>}
                                                sx={{
                                                    fontSize: '1.6rem',
                                                    fontWeight: '400',
                                                }}
                                            />
                                        </MenuItem>
                                    );
                                }}
                            />
                            {this.renderModalButton("flex-end",this.toggleMoveStage, "Done")}
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={isDeleteStage}
                    onClose={this.toggleDeleteStage}
                    aria-labelledby="delete-stage-modal"
                    aria-describedby="delete-stage-form"
                >
                    <Box sx={stageDetailsModalStyle}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <Typography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '2rem' }}>Delete Stage {singleStage.id}</Typography>
                            <IconButton onClick={this.toggleDeleteStage}>
                                <CloseIcon style={{ height: "2rem", width: '2rem' }} />
                            </IconButton>
                        </Box>

                        <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem", padding: 0, width: '100%' }} />
                        <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="h6" style={{ fontSize: '1.2rem' }}>{`Are you sure want to delete stage: ${singleStage.id} ?`}</Typography>
                        </Box>
                        <Box component="form" noValidate autoComplete="off" onSubmit={this.handleDeleteStage}>
                            {this.renderModalButton("center",this.toggleDeleteStage,"Delete")}
                        </Box>
                    </Box>
                </Modal>

            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const stageDetailContainerStyle = {
    padding: "2rem 2rem 1rem",
    borderRadius: "1.2rem",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
    boxSizing: "border-box",
    minHeight: 'calc(100vh - 2.5rem)'
};

const stageDetailsDividerStyle = {
    margin: "1.5rem 0",
    backgroundColor: "#1A4A4233",
};

const stageDetailDeleteIconStyle = {
    backgroundColor: "#EF444433",
    color: "#DC2626",
    padding: "0.45rem",
    borderRadius: "0.6rem",
};
const stageDetailAddButtonStyle = {
    backgroundColor: "#1A4A424D",
    color: "#1A4A42",
    padding: "1rem",
    borderRadius: "0.8rem",
};
const stageDetailsModalStyle = {
    position: 'absolute',
    borderRadius: '0.8rem',
    boxShadow: 2,
    transform: 'translate(-50%, -50%)',
    width: '40rem',
    top: '50%',
    left: '50%',
    bgcolor: 'background.paper',
    p: 3,
};

// Customizable Area End
